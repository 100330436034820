import App from "@iso/redux/app/reducer";
import articlesReducer from "@iso/redux/articles/reducer";
import feedbacksReducer from "@iso/redux/feedbacks/reducer";
import LanguageReducer from "@iso/redux/languages/reducer";
import mediaReducer from "@iso/redux/medias/reducer";
import menuReducer from "@iso/redux/menu/reducer";
import pagesReducer from "@iso/redux/pages/reducer";
import settingsReducer from "@iso/redux/settings/reducer";
import setionsReducer from "@iso/redux/sections/reducer";
import tagsReducer from "@iso/redux/tags/reducer";
import translatesReducer from "@iso/redux/translates/reducer";
import themeReducer from "@iso/redux/themeSwitcher/reducer";
import usersReducer from "@iso/redux/users/reducer";
import priceRangesReducer from "@iso/redux/priceRanges/reducer";
import reservationsReducer from "@iso/redux/reservations/reducer";
import customerRequestsReducer from "@iso/redux/customer-requests/reducer";
import popularDestinationsReducer from "@iso/redux/popularDestinations/reducer";
import { combineReducers } from "redux";

const reducers = combineReducers({
  Auth: usersReducer,
  Articles: articlesReducer,
  PagesReducer: pagesReducer,
  Tags: tagsReducer,
  Settings: settingsReducer,
  ThemeSwitcher: themeReducer,
  LanguageReducer,
  FeedbacksReducer: feedbacksReducer,
  MenuReducer: menuReducer,
  MediaReducer: mediaReducer,
  TranslateReducer: translatesReducer,
  SectionsReducer: setionsReducer,
  PriceRangesReducer: priceRangesReducer,
  ReservationsReducer: reservationsReducer,
  CustomerRequestsReducer: customerRequestsReducer,
  PopularDestinationsReducer: popularDestinationsReducer,
  App: App,
});

export default reducers;
