export const GET_POPULAR_DESTINATIONS_REQUEST = "GET_POPULAR_DESTINATIONS_REQUEST";
export const GET_POPULAR_DESTINATIONS_SUCCESS = "GET_POPULAR_DESTINATIONS_SUCCESS";

export const SHOW_POPULAR_DESTINATION_REQUEST = "SHOW_POPULAR_DESTINATION_REQUEST";
export const SHOW_POPULAR_DESTINATION_SUCCESS = "SHOW_POPULAR_DESTINATION_SUCCESS";

export const POST_POPULAR_DESTINATION_REQUEST = "POST_POPULAR_DESTINATION_REQUEST";
export const POST_POPULAR_DESTINATION_SUCCESS = "POST_POPULAR_DESTINATION_SUCCESS";

export const PUT_POPULAR_DESTINATION_REQUEST = "PUT_POPULAR_DESTINATION_REQUEST";
export const PUT_POPULAR_DESTINATION_SUCCESS = "PUT_POPULAR_DESTINATION_SUCCESS";

export const DELETE_POPULAR_DESTINATION_REQUEST = "DELETE_POPULAR_DESTINATION_REQUEST";
export const DELETE_POPULAR_DESTINATION_SUCCESS = "DELETE_POPULAR_DESTINATION_SUCCESS";
