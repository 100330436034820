export const GET_SECTIONS_REQUEST = "GET_SECTIONS_REQUEST";
export const GET_SECTIONS_SUCCESS = "GET_SECTIONS_SUCCESS";

export const SHOW_SECTION_REQUEST = "SHOW_SECTION_REQUEST";
export const SHOW_SECTION_SUCCESS = "SHOW_SECTION_SUCCESS";

export const POST_SECTION_REQUEST = "POST_SECTION_REQUEST";
export const POST_SECTION_SUCCESS = "POST_SECTION_SUCCESS";

export const PUT_SECTION_REQUEST = "PUT_SECTION_REQUEST";
export const PUT_SECTION_SUCCESS = "PUT_SECTION_SUCCESS";

export const DELETE_SECTION_REQUEST = "DELETE_SECTION_REQUEST";
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS";

export const GET_SECTIONS_TYPES_REQUEST = "GET_SECTIONS_TYPES_REQUEST";
export const GET_SECTIONS_TYPES_SUCCESS = "GET_SECTIONS_TYPES_SUCCESS";
