export const GET_SETTINGS_REQUEST = "GET_SETTINGS_REQUEST";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";

export const SHOW_SETTING_REQUEST = "SHOW_SETTING_REQUEST";
export const SHOW_SETTING_SUCCESS = "SHOW_SETTING_SUCCESS";

export const POST_SETTING_REQUEST = "POST_SETTING_REQUEST";
export const POST_SETTING_SUCCESS = "POST_SETTING_SUCCESS";

export const PUT_SETTING_REQUEST = "PUT_SETTING_REQUEST";
export const PUT_SETTING_SUCCESS = "PUT_SETTING_SUCCESS";

export const DELETE_SETTING_REQUEST = "DELETE_SETTING_REQUEST";
export const DELETE_SETTING_SUCCESS = "DELETE_SETTING_SUCCESS";
