import * as ACTIONS from "@iso/redux/reservations/actionTypes";

const initialState = {
  reservations: {
    list: [],
    total: 0,
  },
};

function reservationsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        ...{ reservations: payload },
      };
    }
    default:
      return state;
  }
}

export default reservationsReducer;
