export const GET_PRICE_RANGES_REQUEST = "GET_PRICE_RANGES_REQUEST";
export const GET_PRICE_RANGES_SUCCESS = "GET_PRICE_RANGES_SUCCESS";

export const SHOW_PRICE_RANGE_REQUEST = "SHOW_PRICE_RANGE_REQUEST";
export const SHOW_PRICE_RANGE_SUCCESS = "SHOW_PRICE_RANGE_SUCCESS";

export const POST_PRICE_RANGE_REQUEST = "POST_PRICE_RANGE_REQUEST";
export const POST_PRICE_RANGE_SUCCESS = "POST_PRICE_RANGE_SUCCESS";

export const PUT_PRICE_RANGE_REQUEST = "PUT_PRICE_RANGE_REQUEST";
export const PUT_PRICE_RANGE_SUCCESS = "PUT_PRICE_RANGE_SUCCESS";

export const DELETE_PRICE_RANGE_REQUEST = "DELETE_PRICE_RANGE_REQUEST";
export const DELETE_PRICE_RANGE_SUCCESS = "DELETE_PRICE_RANGE_SUCCESS";
