import * as ACTIONS from "@iso/redux/customer-requests/actionTypes";

const initialState = {
  customerRequests: {
    list: [],
    total: 0,
  },
};

function customerRequestsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_CUSTOMER_REQUESTS_SUCCESS: {
      return {
        ...state,
        ...{ customerRequests: payload },
      };
    }
    default:
      return state;
  }
}

export default customerRequestsReducer;
