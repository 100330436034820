export const GET_TAGS_REQUEST = "GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";

export const SHOW_TAG_REQUEST = "SHOW_TAG_REQUEST";
export const SHOW_TAG_SUCCESS = "SHOW_TAG_SUCCESS";

export const POST_TAG_REQUEST = "POST_TAG_REQUEST";
export const POST_TAG_SUCCESS = "POST_TAG_SUCCESS";

export const PUT_TAG_REQUEST = "PUT_TAG_REQUEST";
export const PUT_TAG_SUCCESS = "PUT_TAG_SUCCESS";

export const DELETE_TAGS_REQUEST = "DELETE_TAGS_REQUEST";
export const DELETE_TAGS_SUCCESS = "DELETE_TAGS_SUCCESS";
