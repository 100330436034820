import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import reducers from "./Reducers";
import { logger } from "redux-logger";

export function getStore(initialState) {
  let store;

  if (process.env.NODE_ENV === "development") {
    store = createStore(reducers, initialState, applyMiddleware(thunk, logger));
  } else {
    store = createStore(reducers, initialState, applyMiddleware(thunk));
  }

  return store;
}
