import React from "react";
import axios from "axios";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import themes from "@iso/config/theme/theme.config";
import AppLocale from "@iso/config/translation";
import AuthService from "@iso/services/AuthService";

const HTTP_CODES = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

axios.interceptors.request.use(
  function (config) {
    config.headers = { ...config.headers };

    // If the called API endpoint contains 'secured'.
    if (config.url.indexOf("secured") > 1) {
      const securityToken = localStorage.getItem("id_token");

      if (!securityToken) {
        return config;
      }

      config.headers["Authorization"] = `Bearer ${securityToken}`;
    }

    if (
      window.location.pathname.split("/").indexOf("share-link") !== -1 &&
      localStorage.getItem("X-GHOST-IDENTIFIER") &&
      !window.location.pathname.includes("preview") &&
      !config?.url?.includes("cancel-process")
    ) {
      config.headers["X-GHOST-IDENTIFIER"] =
        localStorage.getItem("X-GHOST-IDENTIFIER");
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response) => {
    switch (response.status) {
      case HTTP_CODES.OK:
        break;
      case HTTP_CODES.CREATED:
        // showSnackbarSuccess('Data has been succcessfully created');
        break;

      default:
        break;
    }

    return response;
  },
  async (error) => {
    const status =
      error.response && error.response.status ? error.response.status : null;

    switch (status) {
      case HTTP_CODES.UNAUTHORIZED:
        // If 401, logout.
        AuthService.logout();
        window.location.reload();
        break;
      case HTTP_CODES.BAD_REQUEST:
        break;
      case HTTP_CODES.FORBIDDEN:
      case HTTP_CODES.NOT_FOUND:
        if (error?.response?.config?.url.indexOf("/token") > -1) {
          AuthService.logout();

          return;
        }

        break;
      case HTTP_CODES.INTERNAL_SERVER_ERROR:
        break;

      case HTTP_CODES.CONFLICT:
        return Promise.reject(error);

      default:
        break;
    }

    return Promise.reject(error);
  }
);

export default function AppProvider({ children }) {
  const currentAppLocale = AppLocale.en;
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes.defaultTheme}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
