import * as ACTIONS from "@iso/redux/sections/actionTypes";

const initialState = {
  sections: {
    list: [],
    total: 0,
  },
};

function sectionsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_SECTIONS_SUCCESS: {
      return {
        ...state,
        ...{ sections: payload },
      };
    }
    default:
      return state;
  }
}

export default sectionsReducer;
