export const GET_MENUS_REQUEST = "GET_MENUS_REQUEST";
export const GET_MENUS_SUCCESS = "GET_MENUS_SUCCESS";

export const SHOW_MENU_REQUEST = "SHOW_MENU_REQUEST";
export const SHOW_MENU_SUCCESS = "SHOW_MENU_SUCCESS";

export const POST_MENU_REQUEST = "POST_MENU_REQUEST";
export const POST_MENU_SUCCESS = "POST_MENU_SUCCESS";

export const PATCH_MENU_REQUEST = "PUT_MENU_REQUEST";
export const PATCH_MENU_SUCCESS = "PUT_MENU_SUCCESS";
