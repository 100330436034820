// const testRoutes = [
//   { path: "" },
//   { path: "my-profile" },
//   { path: "leafletmap" },
//   { path: "allFormComponent" },
//   { path: "InputField" },
//   { path: "editor" },
//   { path: "stepperForms" },
//   { path: "FormsWithValidation" },
//   { path: "progress" },
//   { path: "button" },
//   { path: "tab" },
//   { path: "autocomplete" },
//   { path: "checkbox" },
//   { path: "radiobox" },
//   { path: "selectbox" },
//   { path: "transfer" },
//   { path: "alert" },
//   { path: "modal" },
//   { path: "message" },
//   { path: "breadcrumb" },
//   { path: "dropdown" },
//   { path: "op_tag" },
//   { path: "op_timeline" },
//   { path: "uppy" },
//   { path: "dropzone" },
// ];

const options = [
  {
    key: "cms",
    label: "sidebar.cms",
    leftIcon: "ion-ios-cog",
    children: [
      {
        key: "menu",
        label: "sidebar.menu",
      },
      {
        key: "pages",
        label: "sidebar.pages",
      },
      {
        key: "settings",
        label: "sidebar.settings",
      },
      {
        key: "translates",
        label: "sidebar.translates",
      },
      {
        key: "languages",
        label: "sidebar.languages",
      },
    ],
  },
  {
    key: "articles-list",
    label: "sidebar.articles",
    leftIcon: "ion-ios-pint-outline",
    children: [
      {
        key: "articles",
        label: "sidebar.articles",
      },
    ],
  },
  {
    key: "sections",
    label: "sidebar.sections",
    eventKey: "sidebar.sections",
    leftIcon: "ion-ios-list",
    children: [
      {
        key: "sections-reviews",
        label: "sidebar.section_reviews",
        eventKey: "sidebar.section_reviews",
      },
      {
        key: "sections-offers",
        label: "sidebar.section_offers",
        eventKey: "sidebar.section_offers",
      },
    ],
  },
  {
    key: "home",
    label: "sidebar.home",
    eventKey: "sidebar.home",
    leftIcon: "ion-ios-home",
    children: [
      {
        key: "sections-hero-home",
        label: "sidebar.home_hero",
        eventKey: "sidebar.home_hero",
      },
      {
        key: "sections-home-info-tickets",
        label: "sidebar.home_info_tickets",
        eventKey: "sidebar.home_info_tickets",
      },
      {
        key: "sections-home-info-clients",
        label: "sidebar.home_info_clients",
        eventKey: "sidebar.home_info_clients",
      },
      {
        key: "sections-home-advantages",
        label: "sidebar.home_advantages",
        eventKey: "sidebar.home_advantages",
      },
      {
        key: "sections-home-how-reserve",
        label: "sidebar.home_how_reserve",
        eventKey: "sidebar.home_how_reserve",
      },
    ],
  },
  {
    key: "about",
    label: "sidebar.about",
    eventKey: "sidebar.about",
    leftIcon: "ion-ios-people",
    children: [
      {
        key: "sections-about-hero",
        label: "sidebar.about_hero",
        eventKey: "sidebar.about_hero",
      },
      {
        key: "sections-about-hero-advantages",
        label: "sidebar.about_hero_advantages",
        eventKey: "sidebar.about_hero_advantages",
      },
      {
        key: "sections-about-info",
        label: "sidebar.about_info",
        eventKey: "sidebar.about_info",
      },
      {
        key: "sections-about-experience",
        label: "sidebar.about_experience",
        eventKey: "sidebar.about_experience",
      },
      {
        key: "sections-about-motto",
        label: "sidebar.about_motto",
        eventKey: "sidebar.about_motto",
      },
    ],
  },

  {
    key: "newsletters",
    label: "sidebar.newsletters",
    eventKey: "sidebar.newsletters",
    leftIcon: "ion-ios-email",
  },
  {
    key: "feedbacks",
    label: "sidebar.feedback",
    eventKey: "sidebar.feedback",
    leftIcon: "ion-android-contacts",
  },
  {
    key: "price-ranges",
    label: "sidebar.price_ranges",
    eventKey: "sidebar.price_ranges",
    leftIcon: "ion-cash",
  },
  {
    key: "reservations",
    label: "sidebar.reservations",
    eventKey: "sidebar.reservations",
    leftIcon: "ion-ios-clock",
  },
  {
    key: "call-requests",
    label: "sidebar.callRequests",
    eventKey: "sidebar.callRequests",
    leftIcon: "ion-ios-telephone",
  },
  {
    key: "popular-destinations",
    label: "sidebar.popular_destinations",
    eventKey: "sidebar.popular_destinations",
    leftIcon: "ion-star",
  },
  // {
  //   key: "test",
  //   label: "sidebar.test",
  //   leftIcon: "ion-bag",
  //   children: testRoutes.map((q) => ({
  //     key: q.path,
  //     label: "sidebar." + q.path,
  //   })),
  // },
];
export default options;
