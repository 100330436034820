export const GET_RESERVATIONS_REQUEST = "GET_RESERVATIONS_REQUEST";
export const GET_RESERVATIONS_SUCCESS = "GET_RESERVATIONS_SUCCESS";

export const SHOW_RESERVATION_REQUEST = "SHOW_RESERVATION_REQUEST";
export const SHOW_RESERVATION_SUCCESS = "SHOW_RESERVATION_SUCCESS";

export const VIEW_RESERVATION_REQUEST = "VIEW_RESERVATION_REQUEST";
export const VIEW_RESERVATION_SUCCESS = "VIEW_RESERVATION_SUCCESS";

export const COMPLETE_RESERVATION_REQUEST = "COMPLETE_RESERVATION_REQUEST";
export const COMPLETE_RESERVATION_SUCCESS = "COMPLETE_RESERVATION_SUCCESSESS";

export const DELETE_RESERVATION_REQUEST = "DELETE_RESERVATION_REQUEST";
export const DELETE_RESERVATION_SUCCESS = "DELETE_RESERVATION_SUCCESS";
