import * as ACTIONS from "@iso/redux/users/actionTypes";
import AuthService from "@iso/services/AuthService";

const initialState = {
  idToken: localStorage.getItem("id_token") ?? null,
  user: true,
  profile: {},
  loading: false,
  statistics: {},
  tabsSettings: [],
  retailPrices: [],
  balances: [],
  stores: [],
};

function usersReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_USER_REQUEST: {
      return {
        ...state,
        ...{ profile: payload },
      };
    }
    case ACTIONS.LOGIN_SUCCESS:
      AuthService.authenticate(payload?.data);
      return {
        ...state,
        ...{ user: payload },
      };

    case ACTIONS.GET_SECURITY_TOKEN_SUCCESS:
      AuthService.setSecurityToken(payload?.bearerToken);

      return {
        ...state,
        ...{ user: payload },
      };

    case ACTIONS.CREATE_USER_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case ACTIONS.USER_REGISTER_REQUEST:
      return {
        ...state,
        loading: payload.loading,
      };

    case ACTIONS.USER_REGISTER_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: payload.loading,
      };

    case ACTIONS.USER_REGISTER_ERROR:
      return {
        ...state,
        loading: payload.loading,
      };

    case ACTIONS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case ACTIONS.LOGOUT_SUCCESS:
      AuthService.logout();

      return {
        ...state,
        ...payload,
      };

    case ACTIONS.GET_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      };

    case ACTIONS.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
      };

    case ACTIONS.UPDATE_USER_SUCCESS:
      return {
        ...state,
        profile: payload,
        loading: false,
      };

    case ACTIONS.DELETE_USER_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
}

export default usersReducer;
