import * as ACTIONS from "@iso/redux/popularDestinations/actionTypes";

const initialState = {
  popularDestinations: {
    list: [],
    total: 0,
  },
};

function popularDestinationsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_POPULAR_DESTINATIONS_SUCCESS: {
      return {
        ...state,
        ...{ popularDestinations: payload },
      };
    }
    default:
      return state;
  }
}

export default popularDestinationsReducer;
