// GET ARTICLES
export const GET_LANGUAGES_REQUEST = "GET_LANGUAGES_REQUEST";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";

export const GET_LANGUAGE_REQUEST = "GET_LANGUAGE_REQUEST";
export const GET_LANGUAGE_REQUEST_SUCCESS = "GET_LANGUAGE_REQUEST_SUCCESS";

export const POST_LANGUAGES_REQUEST = "POST_LANGUAGES_REQUEST";
export const POST_LANGUAGES_REQUEST_SUCCESS = "POST_LANGUAGES_REQUEST_SUCCESS";

export const PUT_LANGUAGE_REQUEST = "PUT_LANGUAGE_REQUEST";
export const PUT_LANGUAGE_SUCCESS = "PUT_LANGUAGE_SUCCESS";

export const DELETE_LANGUAGE_REQUEST = "DELETE_LANGUAGE_REQUEST";
export const DELETE_LANGUAGE_REQUEST_SUCCESS = "DELETE_LANGUAGE_REQUEST_SUCCESS";
